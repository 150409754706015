import {
  Experience_Get,
  Experience_ListCurrent,
} from '@/chore/services/Profile.service';
import {
  Profile_Get,
  Profile_GetByHash,
} from '@/chore/services/Profile.service';
import {
  Certification_Get,
  Certification_ListCurrent,
} from '@/chore/services/Profile.service';
import { useAuth } from '@/lib/client/hooks/useAuth';
import { useQuery } from '@tanstack/react-query';

// PROFILE
export function useProfile() {
  const { isLoggedIn } = useAuth();

  const query = useQuery({
    queryKey: [`profile-current`],
    queryFn: Profile_Get,
    refetchInterval: 1000 * 60 * 5,
    retry: false,
    enabled: isLoggedIn,
  });
  return query;
}
export function useProfileByHash(profileHash: string) {
  const query = useQuery({
    queryKey: [`profile`, profileHash],
    queryFn: async () => await Profile_GetByHash(profileHash),
    refetchInterval: 5 * 60 * 1000,
    enabled: !!profileHash,
  });
  return query;
}
// EXPERIENCES
export function useExperiences() {
  const query = useQuery({
    queryKey: ['experiences'],
    queryFn: Experience_ListCurrent,
  });
  return query;
}
export function useExperience(experienceHash: string) {
  const query = useQuery({
    queryKey: [`experiences-${experienceHash}`],
    queryFn: async () => Experience_Get(experienceHash),
    enabled: !!experienceHash.length,
    refetchInterval: 5 * 60 * 1000,
  });
  return query;
}
// CERTIFICATIONS
export function useCertifications() {
  const query = useQuery({
    queryKey: ['certifications'],
    queryFn: Certification_ListCurrent,
  });

  return query;
}
export function useCertification(certificationHash: string) {
  const query = useQuery({
    queryKey: [`certifications-${certificationHash}`],
    queryFn: async () => Certification_Get(certificationHash),
    refetchInterval: 5 * 60 * 1000,
    enabled: !!certificationHash.length,
  });
  return query;
}
